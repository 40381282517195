/** @jsx jsx */
import { useState } from "react"
import { Link } from "gatsby"
import { jsx } from "@emotion/core"
import { animated, useSpring } from "react-spring"
import logo from "../../images/logo_caroline_montague.svg"
import Hamburger from "../Header/Hamburger"
import MobileMenu from "../Header/MobileMenu"
import SocialLinks from "../Header/SocialLinks"
import { navigate } from "@reach/router"

const Nav = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const openMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  const [isLinkActive, setLinkActive] = useState(false)

  const { h } = useSpring({
    from: { h: 0, o: 0, z: 2 },
    h: isLinkActive ? 144 : 0,
  })

  return (
    <div>
      <nav className="container mx-auto flex flex-wrap items-center justify-between py-4 px-4 mb-12">
        <div className="lg:order-2 w-auto lg:w-1/5 lg:text-center">
          <Link className="text-xl text-indigo-500 font-semibold" to="/">
            <img
              className="h-8 md:h-12 lg:h-16"
              src={logo}
              alt="Caroline Montague"
            />
          </Link>
        </div>
        <div className="block lg:hidden z-50  outline-none">
          <button
            className="flex items-center py-2 z-50 outline-none focus:outline-none"
            onClick={() => openMenu()}
          >
            <Hamburger open={isMenuOpen} />
          </button>
        </div>
        <div className="hidden lg:order-1 lg:block w-full lg:w-2/5">
          <div
            className="block lg:inline-block mt-4 lg:mt-0 mr-10 hover:text-blue-700"
            onClick={() => {
              navigate("/#about")
            }}
          >
            About
          </div>
          <div
            className="block lg:inline-block mt-4 lg:mt-0 mr-10  cursor-pointer relative "
            to="/books"
            onMouseEnter={() => setLinkActive(true)}
            onMouseLeave={() => setLinkActive(false)}
          >
            Books
            <animated.div
              style={{
                height: isLinkActive ? h .interpolate(h => `${h}px`) : 0,
              }}
              css={{
                position: "absolute",
                top: "100%",
                left: "0",
                backgroundColor: "#F2F2F2",
                overflow: "hidden",
                zIndex: "10",
                opacity: 1,
                width: "250px",
              }}
            >
              <div css={{ padding: "20px" }}>
              <Link
                  to="/shadows-over-the-spanish-sun"
                  className="inline-block w-full text-black text-base mb-4"
                >
                  Shadows over the Spanish Sun >
                </Link>
                <Link
                  to="/an-italian-affair"
                  className="inline-block w-full text-black text-base mb-4"
                >
                  An Italian Affair >
                </Link>
                <Link
                  to="/a-paris-secret"
                  className="inline-block w-full text-black text-base"
                >
                  A Paris Secrect >
                </Link>
              </div>
            </animated.div>
          </div>
          <div
            className="block lg:inline-block mt-4 lg:mt-0 cursor-pointer "
            onClick={() => {
              navigate("/#burnt-norton")
            }}
          >
            Burnt Norton
          </div>
        </div>
        <div className="hidden lg:order-3 lg:block w-full lg:w-2/5 lg:text-right">
          <SocialLinks />
        </div>
      </nav>
      <MobileMenu open={isMenuOpen} />
    </div>
  )
}

export default Nav
