/** @jsx jsx */
import { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { jsx } from "@emotion/core"
import tw from "tailwind.macro"
import { useStaticQuery, graphql } from "gatsby"

import Nav from "./Nav"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const SiteContainer = styled.div`
    ${tw`
    bg-body text-body font-body mx-auto mt-12 
    `}
  `

  return (
    <SiteContainer>
      <Nav />
      <main className="container mx-auto">{children}</main>
      <footer className="container mx-auto my-8 mb-10 pt-10 border-t border-gray-100 text-center uppercase tracking-wider text-sm font-heading">
        <p>© {new Date().getFullYear()} carolinemontague.co.uk</p>
        <p>All Rights Reserved.</p>
      </footer>
    </SiteContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
