/** @jsx jsx */
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import { jsx } from "@emotion/core"
import SocialLinks from "../Header/SocialLinks"
import logo from "../../images/logo_caroline_montague.svg"

const MobileMenu = ({ open }) => {
  const menuAnimation = useSpring({
    transform: open ? `translate3d(0,0,0)` : `translate3d(100%,0,0)`,
  })

  return (
    <animated.div
      style={menuAnimation}
      className="w-full h-screen bg-white fixed z-40 top-0"
    >
      <div className="flex-col align-middle justify-center w-full h-full bg-white">
        <div className="pt-4 mx-auto text-center">
          <Link className="text-xl block h-10 mt-12 text-center" to="/">
            <img
              className="h-10 inline-block"
              src={logo}
              alt="Caroline Montague"
            />
          </Link>
        </div>
        <div className="mt-16 mx-auto text-center">
        <Link
            to="/shadows-over-the-spanish-sun"
            className="inline-block w-full text-black text-base mb-4"
          >
            Shadows Over the Spanish Sun >
          </Link>
          <Link
            to="/an-italian-affair"
            className="inline-block w-full text-black text-base mb-4"
          >
            An Italian Affair >
          </Link>
          <Link
            to="/a-paris-secret"
            className="inline-block w-full text-black text-base"
          >
            A Paris Secrect >
          </Link>
        </div>
        <div className="mt-10 mx-auto text-center">
          <SocialLinks />
        </div>

        <div className="absolute bottom-0 mb-10 text-center w-full">
          <div className="text-gray-400 font-light mb-4 text-xs mx-auto text-center">
            ©{new Date().getFullYear()} CarolineMontague.com. All Rights
            Reserved.
          </div>
        </div>
      </div>
    </animated.div>
  )
}

export default MobileMenu
