import React from "react"
import { useSpring, animated, config } from "react-spring"

const Hamburger = ({ open }) => {
  const openedTransformationConfig = {
    top: "translate(0, 1) rotate(0)",
    center: "translate(0, 12) rotate(0)",
    bottom: "translate(0, 22) rotate(0)",
  }

  const closedTransformationConfig = {
    top: "translate(0, 22) rotate(-45)",
    center: "translate(0, 0) rotate(45)",
    bottom: "translate(0, 22) rotate(-45)",
  }

  const { top, center, bottom } = useSpring({
    to: open ? closedTransformationConfig : openedTransformationConfig,
    config: config.stiff,
  })

  return (
    <svg
      className="fill-current text-black h-6 w-8 outline-none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Mobile Menu</title>
      <animated.rect width="100%" height="1" rx="3" transform={top} />
      <animated.rect width="100%" height="1" rx="3" transform={center} />
      <animated.rect width="100%" height="1" rx="3" transform={bottom} />
    </svg>
  )
}

export default Hamburger
