import React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
const HideLink = styled.span`
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
`

const SocialLinks = () => {
  return (
    <div className="flex flex-row justify-center absolute bottom-0 mb-32 lg:mb-0 w-full lg:w-auto lg:pb-px lg:relative lg:justify-end">
      <a
        className="block lg:inline-block mt-4 lg:mt-0 mr-10 text-blue-900 hover:text-blue-700"
        href="https://www.facebook.com/carolinemontagueauthor/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          className="h-3"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 7 14"
        >
          <g data-name="Layer 2">
            <path
              d="M1.75 4.67H0V7h1.75v7h2.92V7h2.12L7 4.67H4.67v-1c0-.55.11-.77.65-.77H7V0H4.78c-2.1 0-3 .92-3 2.69z"
              fill="#2f364a"
              data-name="Layer 1"
            />
          </g>
        </svg>
        <HideLink>Facebook</HideLink>
      </a>
      <a
        className="block lg:inline-block mt-4 lg:mt-0 text-blue-900 hover:text-blue-700"
        href="https://twitter.com/cmontagueauthor"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          className="h-3 "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 17.23 14"
        >
          <g data-name="Layer 2">
            <path
              d="M17.23 1.66a7 7 0 01-2 .55A3.5 3.5 0 0016.75.26a7.24 7.24 0 01-2.24.86 3.54 3.54 0 00-6 3.22A10 10 0 011.2.65a3.54 3.54 0 001.09 4.72 3.49 3.49 0 01-1.6-.45 3.54 3.54 0 002.84 3.51 3.44 3.44 0 01-1.6.06A3.54 3.54 0 005.23 11 7.12 7.12 0 010 12.41 10 10 0 005.42 14a10 10 0 0010-10.51 7.33 7.33 0 001.81-1.83z"
              fill=""
              data-name="Layer 1"
            />
          </g>
        </svg>
        <HideLink>twitter</HideLink>
      </a>
    </div>
  )
}

export default SocialLinks
